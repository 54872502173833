<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import RecursiveRow from './RecursiveRow.svelte'

  const dispatch = createEventDispatcher()

  export let depth = 0
  export let rule: any = {}

  function selectRule (rule: any) {
    dispatch('click', rule)
  }

  const selectSubRule = (event: CustomEvent) => {
    dispatch('click', event.detail)
  }
</script>
<div
  class='single-row -depth{depth}'
  role='button'
  tabindex='0'
  title={rule.description}
  on:keypress={() => {}}
  on:click={() => selectRule(rule)}>
  <div class='cell1'>{rule.id}</div>
  <div class='cell2 -depth{depth}'>
    <div>{rule.name}</div>
  </div>
  <div class='cell4'>{rule.id_pos || ''}</div>
</div>
{#if rule.children}
  {#each rule.children as child}
    <RecursiveRow depth={depth + 1} rule={child} on:click={selectSubRule} />
  {/each}
{/if}
<style lang='scss'>
  .single-row {
    display: flex;

    > div {
      padding: 0.4rem;
      border: solid var(--gray-2) 0.1rem;
    }

    > .cell1,
    > .cell4 {
      width: 3.2rem;
    }

    > .cell2 {
      width: 30rem;
      padding: 0 !important;
      border: none;

      &.-depth0 {
        background-color: var(--red-3);
        border-left-width: 0;
      }

      &.-depth1 {
        background-color: var(--yellow-3);
        border-left-width: 1rem;
      }

      &.-depth2 {
        background-color: var(--orange-2);
        border-left-width: 2rem;
      }

      &.-depth3 {
        background-color: var(--green-2);
        border-left-width: 3.2rem;
      }

      &.-depth4 {
        background-color: var(--green-4);
        border-left-width: 3.6rem;
      }

      &.-depth5 {
        background-color: var(--blue-5);
        border-left-width: 4.8rem;
      }

      &.-depth6 {
        background-color: var(--pink-3);
        border-left-width: 6rem;
      }
    }
  }
</style>
