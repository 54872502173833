<script lang='ts'>
  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'

  import ModeratorReportsDoc from '@/components/docs/mix/ModeratorReportsDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  import { ModReportsInitResponse, ReportType } from '@/definitions/api/mod/reportsInit'
  import { ModReportsLoadResponse, Report } from '@/definitions/api/mod/reportsLoad'
  import { ReportCounts } from '@/definitions/definitions'

  document.title = 'Moderate reports'

  let type: ReportType
  /* eslint-disable camelcase */
  const nums: ReportCounts = {
    comment: 0,
    correction: 0,
    forumReply: 0,
    forumTopic: 0,
    general: 0,
    lesson_sentence: 0,
    task: 0,
    test_question: 0,
    test_sentence: 0,
    user: 0
  }
  let reports: Report[] = []
  let cursor = ''
  let loadMore = false
  fetchData('mod/reportsInit', {}).then((data: ModReportsInitResponse) => {
    for (const i in data) {
      const counter = i as ReportType
      nums[counter] = data[counter]
    }
  })

  const setType = (newType: ReportType) => {
    fetchData('mod/reportsLoad', {
      cursor,
      type: newType
    }).then((data: ModReportsLoadResponse) => {
      const prevType = type
      type = newType
      const prevCursor = cursor
      if (prevType && prevType !== type) {
        reports = []
      }
      reports = reports.concat(data.reports).filter((e, i, a) => a.findIndex(f => f.id === e.id) === i)
      cursor = reports.length ? reports[reports.length - 1]?.created_at : ''
      loadMore = !!(cursor && (cursor !== prevCursor))
      if (data.reports?.length < 50) {
        loadMore = false
      }
    })
  }

  const replyReport = (reportId: number) => {
    const actionEl: HTMLInputElement | null = document.getElementById('action-' + reportId) as HTMLInputElement | null
    const messageEl: HTMLTextAreaElement | null = document.getElementById('message-' + reportId) as HTMLTextAreaElement | null
    if (actionEl === null || messageEl === null) {
      return
    }
    const action = actionEl.value
    fetchData('mod/reportsReply', {
      action,
      message: messageEl.value,
      reportId
    }).then(() => {
      const report = reports.find(e => e.id === reportId)
      if (report) report.action = action
      if (action !== 'mark') {
        nums[type]--
        reports = reports.filter(e => e.id !== reportId)
      } else {
        reports = reports
      }
    })
  }

  const loadMoreReports = () => {
    setType(type)
  }

</script>
<h2>Moderate reports</h2>
<div>
  <div class='nav-buttons'>
    <button
      class:-active={type === 'user'}
      type='button'
      on:click={() => setType('user')}>User <small>{nums.user}</small></button>
    <button
      class:-active={type === 'task'}
      type='button'
      on:click={() => setType('task')}>Task <small>{nums.task}</small></button>
    <button
      class:-active={type === 'correction'}
      type='button'
      on:click={() => setType('correction')}>Correction <small>{nums.correction}</small></button>
    <button
      class:-active={type === 'comment'}
      type='button'
      on:click={() => setType('comment')}>Comment <small>{nums.comment}</small></button>
    <button
      class:-active={type === 'test_question'}
      type='button'
      on:click={() => setType('test_question')}>Test question <small>{nums.test_question}</small></button>
    <button
      class:-active={type === 'general'}
      type='button'
      on:click={() => setType('general')}>General <small>{nums.general}</small></button>
    <button
      class:-active={type === 'forumReply'}
      type='button'
      on:click={() => setType('forumReply')}>Forum reply <small>{nums.forumReply}</small></button>
    <button
      class:-active={type === 'forumTopic'}
      type='button'
      on:click={() => setType('forumTopic')}>Forum topic <small>{nums.forumTopic}</small></button>
    <button
      class:-active={type === 'test_sentence'}
      type='button'
      on:click={() => setType('test_sentence')}>Test sentence <small>{nums.test_sentence}</small></button>
    <button
      class:-active={type === 'lesson_sentence'}
      type='button'
      on:click={() => setType('lesson_sentence')}>Lesson sentence <small>{nums.lesson_sentence}</small></button>
  </div>
  {#if type}
    {#if reports.length}
      <table class='table'>
        <tr>
          <th>Id</th>
          <th>Reporter</th>
          <th>Type</th>
          <th>Description</th>
          {#if type === 'user'}
            <th>User</th>
          {:else if type === 'task'}
            <th>Task</th>
          {:else if type === 'correction'}
            <th>Task</th>
            <th>Correction</th>
          {:else if type === 'comment'}
            <th>Task</th>
            <th>Comment</th>
          {:else if type === 'test_question'}
            <th>Test id</th>
            <th>Id word</th>
            <th>Word</th>
            <th>Test type</th>
            <th>Languages</th>
            <th style='word-break: break-all;'>Question</th>
            <th>Solution</th>
          {:else}
            <th>Response</th>
          {/if}
          <th>Message</th>
          <th />
        </tr>
        {#each reports as report, index(report.id)}
          <tr class='status-{report.action}'>
            <td>{report.id}</td>
            <td><a href='/entry/users/{report.reporterId}'>{report.reporterNick || report.reporterName}</a></td>
            <td>{report.type}</td>
            <td>{report.description}</td>
            {#if report.reportType === 'user'}
              <td><a href='/entry/users/{report.userId}'>{report.userNick || report.userName}</a></td>
            {:else if report.reportType === 'task'}
              <td><a href='/entry/tasks/{report.taskId}'>{report.taskId}</a></td>
            {:else if report.reportType === 'correction'}
              <td><a href='/entry/tasks/{report.taskId}'>{report.taskId}</a></td>
              <td><a href='/entry/task_corrections/{report.correctionId}'>{report.correctionId}</a></td>
            {:else if report.reportType === 'comment'}
              <td><a href='/entry/tasks/{report.taskId}'>{report.taskId}</a></td>
              <td><a href='/entry/task_comments/{report.commentId}'>{report.commentId}</a></td>
            {:else if report.reportType === 'test_question'}
              <td><a href='/entry/tests/{report.testId}'>{report.testId}</a></td>
              <td><a href='/entry/words/{report.idWord}'>{report.idWord}</a></td>
              <td>{report.word}</td>
              <td>{['', 'radio', 'flash', 'audio', 'written', 'missing', 'sentence_audio'][report.testType]}</td>
              <td>{languages.filter(e => 'idLanguage' in report && e.id === report.idLanguage)?.[0]?.name}</td>
              <td style='word-break: break-all;'>{report.question.replace(/::/g, ', ')}</td>
              <td>{report.solution}</td>
            {:else if report.reportType === 'lesson_sentence'}
              <td><a href='/entry/sentences/{report.sentence.id}'>{report.sentence.sentence}</a></td>
              <td><a href='/entry/words/{report.word.id}'>{report.word.word}</a></td>
            {:else if report.reportType === 'forumReply'}
              <td><a href='/entry/forum_topic/{report.forumTopicId}'>{report.forumTopicId}</a></td>
              <td><a href='/entry/forum_reply/{report.forumReplyId}'>{report.forumReplyId}</a></td>
            {:else if report.reportType === 'forumTopic'}
              <td><a href='/entry/forum_topic/{report.forumTopicId}'>{report.forumTopicId}</a></td>
            {:else if report.reportType === 'test_sentence'}
              <td><a href='/entry/tests/{report.testId}'>{report.testId}</a></td>
              <td><a href='/entry/sentences/{report.idWord}'>{report.idWord}</a></td>
              <td>{report.word}</td>
              <td>{['', 'radio', 'flash', 'audio', 'written', 'missing', 'sentence_audio'][report.testType]}</td>
              <td>{languages.filter(e => 'idLanguage' in report && e.id === report.idLanguage)?.[0]?.name}</td>
              <td style='word-break: break-all;'>{report.question}</td>
              <td>{report.solution}</td>
            {:else}
              <td>{report.reportType} - {JSON.stringify(report)}</td>
            {/if}
            {#if report.action !== 'ignore' && report.action !== 'accept'}
              <td><textarea id='message-{report.id}' cols='20' rows='1' name='message' /></td>
              <td>
                <div class='custom-select'>
                  <select id='action-{report.id}' name=''>
                    <option value=''>Choose</option>
                    <option value='mark'>Mark</option>
                    <option value='ignore'>Ignore</option>
                    <option value='accept'>Accept</option>
                  </select>
                </div>
                <button
                  class='send-button'
                  type='button'
                  on:click={() => replyReport(report.id)}>Send
                </button>
              </td>
            {:else}
              <td />
              <td />
            {/if}
          </tr>
        {/each}
      </table>
    {:else}
      No reports to show
    {/if}
    {#if loadMore}
      <button type='button' on:click={loadMoreReports} on:mousedown={() => {}}>Load more</button>
    {/if}
  {/if}
  <Documentation dateCompleted='2023-08-26'>
    <ModeratorReportsDoc />
  </Documentation>
</div>

<style lang='scss'>
  .nav-buttons {
    display: flex;
    flex-wrap: wrap;

    > button {
      margin: 0.2rem;
      padding: 0.2rem;
      color: var(--black-text);
      background: var(--white-1);
      border: none;

      > small {
        font-weight: bold;
        color: var(--red-3);
      }

      &.-active {
        color: var(--black-text);
        background: var(--blue-2);
      }
    }
  }

  table {
    max-width: 100%;
  }

  td {
    padding: 0.4rem;
  }

  .status-mark {
    > td {
      background: var(--yellow-2);
    }
  }

  .send-button {
    padding: 0.2rem;
    border: none;
    border-radius: 0.2rem;
  }
</style>
