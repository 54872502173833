<script lang='ts'>
  import { Func } from '@/definitions/definitions'

  export let checked: boolean, handleClickOnHide: Func, current: number, total: number, done: number, pageType: string

  const type = (() => {
    switch (pageType) {
      case 'translate-word':
        return 'translated'
      case 'add-audio-word':
      case 'add-audio-sentence':
        return 'recorded'
      default:
        return 'finished'
    }
  })()
</script>

<div class='page-infobox'>
  <label>Hide {type} pages: <input {checked} type='checkbox' on:change={handleClickOnHide} /></label>
  <p>Page: {current} / {total}</p>
  <p>{type.charAt(0).toUpperCase() + type.slice(1)}: {done} / {total}</p>
</div>

<style lang='scss'>
  .page-infobox {
    margin: 2rem 0;
    text-align: right;
    border-bottom: solid var(--gray-3) 0.1rem;

    > p {
      margin: 1rem 0;
    }
  }
</style>
