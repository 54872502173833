<script lang='ts'>
  // todo: We should use this file for getting different stats from the server that could be interesting for administrators
  // todo: Try to show how many tasks come directly from tasks, not through forum
  import { fetchData } from '@/helpers/fetchHelpers'

  import TasksDashboardDoc from '@/components/docs/mix/TasksDashboardDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  let tasks: any[] = []
  fetchData('mod/tasksDashboard', {}).then((data) => {
    tasks = data.tasks || []
  })
</script>

{#if tasks.length}
  <table>
    <thead>
      <tr>
        <th>Language</th>
        <th>Task type</th>
        <th>Num of tasks</th>
      </tr>
    </thead>
    {#each tasks as task}
      {@const link = `${task.iso}/task/${task.slug}/find/`}
      <tr>
        <td><a href={`https://langoid.com/${link}`}>{task.language}</a></td>
        <td><a href={`https://langoid.com/${link}`}>{task.slug}</a></td>
        <td class='-right'>{task.num}</td>
      </tr>
    {/each}
  </table>
{/if}
<Documentation dateCompleted='2023-08-26'>
  <TasksDashboardDoc />
</Documentation>
<style>
  td,
  th {
    padding: 0.4rem;
    border: solid var(--gray-7) 0.1rem;

    &.-right {
      text-align: right;
    }
  }
</style>
