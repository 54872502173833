<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  export let current: number
  export let count: number
  const around = 5
  const dispatch = createEventDispatcher()

  function range(size: number, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
  }

  function changePage(page: number) {
    if (page !== current) {
      dispatch('change', page)
      current = page
    }
  }

  const getTargetVal = (e: Event) => {
    return parseInt((e.target as HTMLSelectElement)?.value || '1', 10)
  }
</script>
<!-- <Pagination on:change={(e)=>console.log(e.details.page)} /> -->
<nav class='pagination-wrapper'>
  <ul>
    {#each range(around - 1, current - around - 1) as page}
      {#if count > around * 2 && page < 0}
        <li class='empty'><span /></li>
      {/if}
    {/each}
    <li class:disabled={current === 1}><span
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => changePage(1)}> ⇤ </span></li>
    <li class:disabled={current === 1}><span
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => changePage(current - 1)}>«</span></li>
    {#if count < around * 2}
      {#each range(count, 1) as page}
        <li class:active={page === current}><span
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={() => changePage(page)}>{page}</span></li>
      {/each}
    {:else}
      {#each range(current <= around ? around - (around - current) - 1 : around, current <= around ? 1 : current - around) as page}
        <li><span
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={() => changePage(page)}>{page}</span></li>
      {/each}
      <li class='active'><span>{current}</span></li>
      {#each range(count - current < around ? count - current : around, current + 1) as page}
        <li><span
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={() => changePage(page)}>{page}</span></li>
      {/each}
    {/if}
    <li class:disabled={current === count}><span
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => changePage(current + 1)}> » </span></li>
    <li class:disabled={current === count}><span
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={() => changePage(count)}> ⇥ </span></li>
  </ul>

</nav>
<div>
  <div class='custom-select'>
    <select on:change={(e) => changePage(getTargetVal(e))}>
      {#each Array(count) as _, i (i)}
        <option selected={i + 1 === current}>{i + 1}</option>
      {/each}
    </select>
  </div>
  / {count}
</div>
<style lang='scss'>
  .pagination-wrapper {
    display: flex;
    justify-content: center;

    > ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      list-style: none;

      > li {
        > span {
          position: relative;
          display: block;
          min-width: 2.4rem;
          margin-left: 0;
          padding: 0.4rem 0.8rem;
          line-height: 1.2;
          background-color: var(--white-1);
          outline: 0.1rem solid var(--gray-1);
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          user-select: none;
        }

        &.active > span {
          color: var(--white-text);
          background-color: var(--blue-5);
          border: solid var(--blue-5) 0.2rem;
        }

        &.empty > span {
          visibility: hidden;
        }

        &.disabled > span {
          color: var(--white-1);
          background: var(--blue-3);
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }
</style>
