<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { toInt } from '@/helpers/mix'

  import UnclearMeaningDoc from '@/components/docs/admin/UnclearMeaningDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import Pagination from '@/components/Pagination.svelte'

  document.title = 'Unclear Meaning Sentences'

  let page: any = getParameterByName('page') || 1
  let sentences: any = []
  let currentWord: any = {}
  let currentSentence: any = {}
  let forms: any = {}
  let count = 0
  let showFormality = false
  let showExclusive = false
  const loadPage = (pg: number) => {
    page = pg
    fetchData('mod/unclearMeaningLoad', {
      page: pg
    }).then((data) => {
      sentences = Object.values(data.meanings)
      count = data.pagesCount
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + pg)
    })
  }

  loadPage(page)

  function markSpecialWords(word: any) {
    if (word.pos === 4) {
      return `<span style='color:red'>${word.content}</span>`
    }
    if (word.pos === 2) {
      return `<span style='color:rgba(0,0,155,1)'>${word.content}</span>`
    }
    return word.content
  }

  function markWord(word: any, sentence: any) {
    currentWord = word
    currentSentence = sentence
    forms = updateControls(sentence)
  }

  /* selector start */
  function updateControls(sentence: any) {
    const sentenceComment = sentence.sentence_meaning
    let formality,
      exclusive,
      wordComment = '',
      gender = '',
      number = ''

    showFormality = false
    showExclusive = false
    if (toInt(currentWord.pos) === 4) {
      if (currentWord.original === 'I') {
        // do nothing
      } else if (['we', 'us', 'ourself'].includes(currentWord.original)) {
        showExclusive = true
      } else if (['you', 'yourself'].includes(currentWord.original)) {
        showFormality = true
      }
    }
    const props = sentence.meanings[currentWord.order]
    if (props) {
      if (props.comment) wordComment = props.comment
      if (props.gender) gender = props.gender
      if (props.number) number = props.number
      if (props.exclusive) exclusive = props.exclusive
      if (props.formality) formality = props.formality
    }

    return { exclusive, formality, gender, number, sentenceComment, wordComment }
  }

  function saveForm(word: any, sentence: any, forms: any) {
    const params: any = {
      idWord: word.idWord,
      sentence: sentence.id,
      wordOrder: word.order
    }
    for (const i in forms) {
      if (forms[i] !== '' && typeof forms[i] !== 'undefined') {
        params[i] = forms[i]
      }
    }
    fetchData('mod/unclearMeaningSave', params).then(() => {
      loadPage(page)
    })
  }
</script>

<div class='unclear-meaning-page'>
  {#each sentences as sentence}
    {#if sentence.id === currentSentence.id}
      <div class='edit-word-meaning-table'>
        {#if currentWord}
          <table class='level-checker-popup-table'>
            <thead>
            <tr>
              <th>word id</th>
              <th>word</th>
              <th>POS</th>
              <th style='display: none;'>WIF</th>
              <th>Number</th>
              <th>Gender</th>
              {#if showFormality}
                <th>Formality</th>
              {/if}
              {#if showExclusive}
                <th>Exclusive</th>
              {/if}
              <th>Sentence comment</th>
              <th>Word comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{currentWord.idWord}</td>
              <td>{currentWord.content}</td>
              <td>{currentWord.pos}</td>
              <td data-id='' title='' style='display: none;' />
              <td>
                <div class='custom-select'>
                  <select id='plural' bind:value={forms.number} name='plural'>
                    <option value=''>Choose number</option>
                    <option value='plural'>Plural</option>
                    <option value='singular'>Singular</option>
                  </select>
                </div>
              </td>
              <td>

                <div class='custom-select'>
                  <select id='gender' bind:value={forms.gender} name='gender'>
                    <option value=''>Choose gender</option>
                    {#if forms.number === 'singular'}
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                    {:else if forms.number === 'plural'}
                      <option value='males'>All Males</option>
                      <option value='females'>All Females</option>
                      <option value='mixed'>Mixed</option>
                    {:else}
                      <optgroup label='Singular'>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </optgroup>
                      <optgroup label='Plural'>
                        <option value='males'>All Males</option>
                        <option value='females'>All Females</option>
                        <option value='mixed'>Mixed</option>
                      </optgroup>
                    {/if}
                  </select>
                </div>
              </td>
              {#if showFormality}
                <td>
                  <div class='custom-select'>
                    <select bind:value={forms.formality} name=''>
                      <option value=''>Choose formality</option>
                      <option value='formal'>Formal</option>
                      <option value='informal'>Informal</option>
                    </select>
                  </div>
                </td>
              {/if}
              {#if showExclusive}
                <td>
                  <div class='custom-select'>
                    <select bind:value={forms.exclusive} name=''>
                      <option value=''>Choose exclusivity</option>
                      <option value='exclusive'>Exclusive</option>
                      <option value='inclusive'>Inclusive</option>
                    </select>
                  </div>
                </td>
              {/if}
              <td><textarea id='sentenceComment' cols='15' rows='2' bind:value={forms.sentenceComment} name='sentence-comment' /></td>
              <td><textarea id='wordComment' cols='15' rows='2' bind:value={forms.wordComment} name='word-comment' /></td>
              <td><input type='submit' value='Save' on:click={() => saveForm(currentWord, currentSentence, forms)} /></td>
            </tr>
            </tbody>
          </table>
        {/if}
      </div>
    {/if}
    <div id='senence-{sentence.id}' class='single-sentence'>
      <div class='counter'>{sentence.id}</div>
      {#each sentence.content as word}
        {@const meaning = sentence.meanings?.[word.order]}
        {#if word.idWord}
          <div
            class='word'
            class:-active={word.idWord === currentWord.idWord && word.order === currentWord.order && sentence.id === currentSentence.id}
            data-id={word.idWord}
            data-order={word.order}
            data-original={word.original}
            data-pos={word.pos}
            role='button'
            tabindex='0'
            on:keypress={() => {}}
            on:click={() => markWord(word, sentence)}>
            <div class='word-content'>
              {@html markSpecialWords(word)}
            </div>
            {#if meaning}
              {#if meaning.gender}
                <div class='prop -gender'>{meaning.gender}</div>
              {/if}
              {#if meaning.number}
                <div class='prop -number'>{meaning.number}</div>
              {/if}
              {#if meaning.formality}
                <div class='prop -formality'>{meaning.formality}</div>
              {/if}
              {#if meaning.exclusive}
                <div class='prop -exclusive'>{meaning.exclusive}</div>
              {/if}
              {#if meaning.comment}
                <div class='prop -comment'>{meaning.comment}</div>
              {/if}
            {/if}
          </div>
        {:else}
          <div class='space'>{word.content}</div>
        {/if}
      {/each}
      <div class='sentence-comment'>
        {sentence.sentence_meaning || ''}
      </div>
    </div>
  {/each}
</div>
<div style='position: fixed;bottom: 0;left: 0;width: 100vw;'>
  <Pagination {count} current={parseInt(page)} on:change={(ev) => loadPage(ev.detail)} />
</div>
<Documentation dateCompleted='2023-08-26'>
  <UnclearMeaningDoc />
</Documentation>
<style lang='scss'>
  .unclear-meaning-page {
    float: left;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 7.2rem;
    padding: 2rem;
    background: var(--white-1);
  }

  .single-sentence {
    display: flex;
    float: left;
    clear: both;
    min-width: 9.6rem;
    margin: 1rem 0;

    > .counter {
      padding-right: 2rem;
    }

    > .word {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      min-width: 3.2rem;
      min-height: 4.8rem;
      padding: 0.4rem;
      border: solid var(--gray-2) 0.1rem;

      &.-active {
        outline: solid var(--green-4) 0.3rem;
      }

      > .prop {
        display: block;
        max-width: 12.8rem;
        border: dashed var(--gray-2) 0.1rem;

        &.-gender {
          background: rgba(255, 0, 0, 0.1);
        }

        &.-number {
          background: rgba(0, 255, 0, 0.1);
        }

        &.-exclusive {
          background: rgba(0, 0, 255, 0.1);
        }

        &.-formality {
          background: rgba(0, 155, 255, 0.1);
        }

        &.-comment {
          background: var(--blue-1);
        }
      }
    }

    > .space {
      min-width: 1rem;
    }
  }

  .edit-word-meaning-table {
    display: block;
    clear: both;
    width: 100%;
    padding: 1rem;
    background: var(--gray-2);
  }
</style>
