<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'

  document.title = 'Users summary'

  let data: any = {}
  let users: any = []
  let minutes: any = {}
  let missions: any = {}
  let minutesPerDay: any = {}
  const div: any = {}

  const load = async (id = 0) => {
    data = await fetchData('admin/summaryUsers', { cursor: id })
    minutes = { ...minutes, ...data.minutes }
    missions = { ...missions, ...data.missions }
    minutesPerDay = { ...minutesPerDay, ...data.minutesPerDay }
    users = [...users, ...Object.values(data.users || {})]
  }
  load()

  const reduceMinutes = (minutesPerDay: any, user: any) => {
    return minutesPerDay[user.id].reduce((a: any, b: any) => a + b.minutes, 0)
  }
</script>
<table>
  <thead>
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Minutes</th>
      <th>Active</th>
      <th>Missions</th>
      <th>Points</th>
      <th>Level</th>
      <th>Email</th>
    </tr>
  </thead>
  {#each users as user, index(user.id)}
    <tr>
      <td>{user.id}</td>
      <td>{user.name || user.nick}</td>
      <td class='number-cell'>
        {#if minutes[user.id]}
          {minutes[user.id]}
        {/if}
      </td>

      <td
        class='number-cell -link'
        on:keypress={() => {}}
        on:click={() => { div[user.id] = !div[user.id] }}>
        {#if minutesPerDay[user.id]}
          <div class='activeMinutes'>↓</div>
          <div class='initiallyHidden' class:-shown={div[user.id]}>
            {#each minutesPerDay[user.id] as row}
              <div>{row.log_date}</div><b>{row.minutes}</b>
            {/each}
          </div>
          {reduceMinutes(minutesPerDay, user)}
        {/if}
      </td>
      <td class='number-cell'>{missions[user.id] ?? ''}</td>
      <td class='number-cell'>{user.points}</td>
      <td class='number-cell'>{user.level}</td>
      <td>{user.email}</td>
    </tr>
  {/each}
</table>
{#if data.cursor}
  <button
    type='button'
    on:click={() => {
      if (data.cursor)load(data.cursor)
    }}>Next page {data.cursor}</button>
{/if}

<style lang='scss'>
  td,
  th {
    padding: 0.2rem 0.4rem;
    border: solid var(--blue-3) 0.1rem;
  }

  .number-cell {
    text-align: right;

    &.-link {
      cursor: pointer;
    }

    > .initiallyHidden {
      display: none;

      &.-shown {
        display: grid;
        grid-template-columns: 1fr 3.6rem;
        font-size: 1.2rem;
      }
    }

    > .activeMinutes {
      float: left;
      color: var(--red-3);
      cursor: pointer;
    }
  }
</style>
