<script lang='ts'>
  import { onMount, tick } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { capitalize, formDataUnpack, isNumber } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'
  import FormFieldCreator from '@/components/CrudFormFieldCreator.svelte'
  import CrudPageFilter from '@/components/CrudPageFilter.svelte'
  import CrudPagesDoc from '@/components/docs/admin/mix/CrudPagesDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import FormExtraInfo from '@/components/FormExtraInfo.svelte'
  import Overlay from '@/components/Overlay.svelte'
  import Pagination from '@/components/Pagination.svelte'
  import ShowMore from '@/components/ShowMore.svelte'

  export let type = 'index'
  export let currentPage = 1
  export let iso = ''
  let results: any[] = []
  let columns: any[] = []
  let fields: any = {}
  let showForm = false
  let formType = ''
  let draft = false
  let count = 0
  let perPage = 20
  let filter: any = {}
  const sort: any = { column: 'id', order: 'desc' }
  let settings: any = {}

  document.title = capitalize(type) + ': CRUD'

  onMount(() => {
    if (type !== 'index') loadData(currentPage)
  })

  const loadData = (page = 1) => {
    fetchData('mod/crud', { filter, iso, method: 'load', page, sort, type }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        results = data.results
        columns = data.columns
        fields = data.fields
        count = data.count
        perPage = data.perPage
        filter = data.filter
        settings = data.tableSettings
      }
    })
  }
  const tableSort = (column: any) => {
    if (sort.column === column) {
      sort.order = sort.order === 'desc' ? 'asc' : 'desc'
    }
    sort.column = column
    loadData(currentPage)
  }

  const getLabel = (key: string) => fields[key].label || capitalize(key)

  const validateData = (data: any) => {
    const errors = []

    for (const key in data) {
      const item = data[key]
      const field = fields[key]
      if (field) {
        if (item === '' && field.required) {
          errors.push(`${getLabel(key)} is required`)
        }
        if (field.type === 'number') {
          if (item !== '' && !isNumber(item)) {
            errors.push(`${getLabel(key)} must be a number`)
          }
        }
      }
    }
    return errors
  }
  const deleteEntry = (id: number) => {
    if (!confirm('Are you sure you want to delete this entry?')) return false
    const requestData = { id, method: 'delete', type }
    fetchData('mod/crud', requestData).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        results = results.filter((item) => item.id !== id)
      }
    })
  }

  const editEntry = async (id: number) => {
    formType = 'edit'
    const entry = results.filter((item) => item.id === id)
    if (entry.length) {
      showForm = true
      const entryFiltered: any = getFilteredEnty(entry[0])
      // setTimeout(() => {
      await tick()
      for (const key in entryFiltered) {
        if (fields[key]) {
          const el = document.getElementById('field' + key) as HTMLInputElement
          if (el) el.value = entryFiltered[key]
        }
      }
    // }, 100)
    }
  }

  const getFilteredEnty = (entry: any) => {
    const entryFiltered: any = {}
    for (const key in entry) {
      if (typeof entry[key + 'OriginalEntry'] !== 'undefined') {
        entryFiltered[key] = entry[key + 'OriginalEntry']
      } else if (!key.includes('OriginalEntry')) {
        entryFiltered[key] = entry[key]
      }
    }
    return entryFiltered
  }

  const submitEntry = (e: any) => {
    e.preventDefault()
    const form: HTMLFormElement = document.getElementById('crudForm') as HTMLFormElement
    const formData = new FormData(form)
    const formDataObject = formDataUnpack(formData)
    const requestData = { data: formDataObject, method: formType, type }
    const validationErrors = validateData(formDataObject)
    if (validationErrors.length > 0) {
      notifier.error(validationErrors.join('<hr>'))
      return false
    }
    fetchData('mod/crud', requestData).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        showForm = false
        if (formType === 'add') {
          results = [data.result, ...results]
        } else {
          results = results.map((item) => item.id === data.result.id ? data.result : item)
        }
        e.target.reset()
      }
    })
  }

  function editField (column: string, row: any, resultIndex: any) {
    if (!settings.edit) return false // cant' edit table
    if (!fields[column].place.editForm) return false // can't edit field
    if (column === 'id') {
      return false
    }
    if (!results[resultIndex].isEditingStarted) {
      results[resultIndex].isEditingStarted = {}
    }
    results[resultIndex].isEditingStarted[column] = true
    draft = true
  }

  const saveDraftEntries = () => {
    if (!settings.edit) return false
    const fields: any[] = []
    for (const i in results) {
      if (results[i].isEditingStarted) {
        for (const key in results[i].isEditingStarted) {
          const before = results[i][key]
          const el = document.getElementById(`draft-${results[i].id}-${key}`) as HTMLInputElement | HTMLSelectElement
          const after = el ? el.value : ''
          const originalEntry = results[i][key + 'OriginalEntry']
          if (before !== after) fields.push({ i, id: results[i].id, key, originalEntry, value: after })
        }
      }
      results[i].isEditingStarted = undefined
    }
    fetchData('mod/crud', { fields, method: 'saveDrafts', type }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        // results = data.results
        for (const i in fields) {
          const field = fields[i]
          if (results[field.i][field.key + 'OriginalEntry']) {
            results[field.i][field.key + 'OriginalEntry'] = field.value
          } else {
            data.results.forEach((item: any) => {
              if (item.id === field.id) {
                results[field.i] = item
              }
            })
          }
        }
        draft = false
      }
    })
  }

  const changePage = (page: number) => {
    loadData(page)
    currentPage = page
  }

  const applyFilter = (e: CustomEvent) => {
    filter = e.detail
    loadData(1)
  }

  const filterFields = (filterType: string) => {
    const filteredFields: any = {}
    for (const i in fields) {
      if (fields[i].place[filterType]) {
        filteredFields[i] = fields[i]
      }
    }
    return filteredFields
  }

  const closeModal = () => {
    showForm = false
  }

</script>

<Breadcrumb />
<h2>{type}</h2>
{formType}
{#if columns.length}
  {#if settings.edit}
    <input class:hidden={showForm} type='button' value='Add' on:click={() => { showForm = true; formType = 'add' }} />
  {/if}
  {#if showForm}
    <Overlay hidden={!showForm} onClick={closeModal}>
      <form
        id='crudForm'
        class='overlay-content'
        class:hidden={!showForm}
        action='/crud'
        method='post'
        on:submit={submitEntry}>
        {#each Object.keys(fields) as key}
          {#if fields[key].place[`${formType}Form`] && key !== 'id'}
            <p class:hidden={formType === 'edit' && key === 'id'}>
              <label for={'field' + key}>{fields[key].label || capitalize(key)}</label>
              <FormFieldCreator {fields} {key} />
            </p>
          {:else if key === 'id' && formType === 'edit'}
            <p class:hidden={true}>
              <FormFieldCreator {fields} {key} />
            </p>
          {/if}
        {/each}<br />
        <input type='submit' value='Add' />
      </form>
    </Overlay>
  {/if}
{/if}
{#if settings.filter}
  <CrudPageFilter columns={Object.keys(filterFields('find'))} on:change={applyFilter} />
{/if}
{#if results.length}
  {count} results
  <table class='table'>
    <thead>
    <tr class='table-row'>
      {#if settings.edit}
        <th />
      {/if}
      {#if settings.delete}
        <th />
      {/if}
      {#each Object.keys(fields) as key}
        {@const place = fields[key]?.place || {}}
        {#if place.table}
          {#if place.sort}
            <th on:click={() => tableSort(key)}>
              <a
                class='sort-link'
                class:-sortAsc={sort.order === 'asc' && sort.column === key}
                class:-sortDesc={sort.order === 'desc' && sort.column === key}
                href='#todo'>{getLabel(key)}
              </a>
            </th>
          {:else}
            <th>{getLabel(key)}</th>
          {/if}
        {/if}
      {/each}
    </tr>
    </thead>
    {#each results as row, resultIndex}
      <tr class='table-row'>
        {#if settings.edit}
          <th class='edit' on:click={() => editEntry(row.id)}><img alt='Edit' src='https://cdn.langoid.com/static/images/edit.png' /></th>
        {/if}
        {#if settings.delete}
          <th class='delete' on:click={() => deleteEntry(row.id)}><img alt='Delete' src='https://cdn.langoid.com/static/images/delete.png' /></th>
        {/if}
        {#each columns as column}
          {@const place = fields[column]?.place || {}}
          {#if place.table}
            <td class:-editable={place.editForm} on:dblclick={() => { editField(column, row, resultIndex) }}>
              {#if row.isEditingStarted?.[column]}
                <FormFieldCreator {fields} key={column} prefix={`draft-${row.id}-`} {row} />
              {:else if row?.[column]}
                <ShowMore content={row[column]} />
                <FormExtraInfo {column} table={type} value={row[column]} />
              {/if}
            </td>
          {/if}
        {/each}
      </tr>
    {/each}
  </table>
  {#if count > perPage}
    <Pagination count={Math.ceil(count / perPage)} current={currentPage} on:change={(e) => changePage(e.detail)} />
  {/if}
  {#if draft}
    <input class='save-button' type='button' value='Save' on:click={saveDraftEntries} />
  {/if}
{:else}
  <p>No results</p>
{/if}
<Documentation>
  <CrudPagesDoc {type} />
</Documentation>
<style lang='scss'>
  .overlay-content {
    > p {
      margin: 0;
      font-size: 1.2rem;
      line-height: 1.2;
      text-align: right;

      > label {
        float: left;
        width: 9.6rem;
        padding-right: 1rem;
      }
    }

    /* stylelint-disable rscss/no-descendant-combinator */
    :global(input),
    :global(select),
    :global(textarea) {
      min-width: 19.2rem;
      padding: 0.4rem;
    }

    :global(input[type="submit"]),
    :global(input[type="button"]),
    :global(input[type="reset"]) {
      min-width: 7.2rem;
    }
    /* stylelint-enable rscss/no-descendant-combinator */
  }

  .table-row {
    > .edit,
    > .delete {
      cursor: pointer;
    }

    > td {
      position: relative;
      padding: 0;

      > :global(input) {
        display: inline-block;
        width: 100%;
        min-width: auto;
        max-width: 100% !important;
        height: 100%;
        min-height: auto;
        max-height: 100%;
        margin: 0;
        padding: 0.1rem;
      }

      &.-editable {
        background: var(--light-gray-background);
        border: solid var(--gray-3) 0.1rem;
        cursor: pointer;
      }
    }

    > td,
    > th {
      overflow: hidden;
      padding: 0.4rem;
      text-align: left;
      vertical-align: middle;
      border: solid var(--gray-3) 0.1rem;
    }

    > th {
      font-weight: 400;
      background-color: var(--gray-2);

      > a {
        display: block;
        width: 100%;
      }
    }
  }

  /* stylelint-disable-next-line rscss/class-format */
  .hidden {
    display: none;
  }

  table {
    width: 100%;
    background: var(--white-1);
    border-collapse: collapse;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .sort-link {
      position: relative;
      padding-right: 1.8rem;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 0.4rem;
      display: block;
      width: 0;
      height: 0;
      border: solid transparent 0.4rem;
      content: '';
    }

    &.-sortAsc::before {
      margin-top: -1rem;
      border-bottom-color: var(--gray-7);
    }

    &.-sortDesc::after {
      margin-top: 0.1rem;
      border-top-color: var(--gray-7);
    }
  }

  .save-button {
    position: fixed;
    right: 0;
    bottom: 0;
  }
</style>
